<template>
  <div class="MyTeacherCourse">
    <table-list
      title="我的订单"
      :loading="loading"
      :search-form="searchForm"
      :data="myOrderList"
      :columns="columns(this)"
      :btns="btns(this)"
      :operates="operates(this)"
      :options="{ selection: true }"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    ></table-list>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = [
  {
    label: '订单编号',
    prop: 'orderId',
  },
  {
    label: '订单内容',
    prop: 'productName',
  },
  {
    label: '支付渠道',
    type: 'select',
    prop: 'payType',
    children: [
      { value: 'ALIPAY', label: '支付宝' },
      { value: 'WXPAY', label: '微信' },
      { value: 'BALANCE', label: '余额' },
    ],
  },
  {
    label: '支付状态',
    type: 'select',
    prop: 'orderStatus',
    children: [
      { value: 'SUCCESS', label: '已支付' },
      { value: 'WAIT', label: '未支付' },
      { value: 'REFUND', label: '已退款' },
      { value: 'CLOSE', label: '已取消' },
    ],
  },
  {
    label: '创建时间',
    type: 'picker',
    prop: 'createTime',
  },
  {
    label: '支付时间',
    type: 'picker',
    prop: 'payTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderId',
    label: '订单编号',
    minWidth: 120,
  },
  {
    prop: 'productName',
    label: '订单内容',
    minWidth: 100,
  },
  {
    label: '原价',
    render: (h, { row }) => {
      return h('div', row.productSumAmount ? '￥' + row.productSumAmount : '')
    },
  },
  {
    label: '实付金额',
    render: (h, { row }) => {
      return h('div', row.amount ? '￥' + row.amount : '')
    },
  },
  {
    prop: '',
    label: '优惠金额',
    minWidth: 100,
  },
  {
    prop: 'payTypeName',
    label: '支付渠道',
    minWidth: 100,
  },
  {
    prop: 'createTimeShow',
    label: '创建时间',
    minWidth: 120,
  },
  {
    prop: 'payTimeShow',
    label: '支付时间',
    minWidth: 120,
  },
  {
    prop: 'orderStatusName',
    label: '支付状态',
    minWidth: 120,
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '收支明细',
    method: _this.handleMx,
  },
  {
    label: '销售统计',
    method: _this.handleTj,
  },
  {
    label: '导出',
    method: _this.handleDc,
    type: 'danger',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleXq,
  },
]
import TableList from '@/components/TableList'
import { merchantTeacherFindMyOrder, merchantTeacherFindMyOrderDetailExport } from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      loading: false,
      columns,
      operates,
      btns,
      searchForm,
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
        orderId: '',
        productName: '',
        payType: '',
        orderStatus: '',
        beginPayTime: '',
        endPayTime: '',
        beginCreateTime: '',
        endCreateTime: '',
      },
      myOrderList: [],
      selectOrders: [],
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(merchantTeacherFindMyOrder(this.pager))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.myOrderList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.pager.orderId = queryParams.orderId
      this.pager.productName = queryParams.productName
      this.pager.payType = queryParams.payType
      this.pager.orderStatus = queryParams.orderStatus
      if (queryParams.createTime) {
        this.pager.beginCreateTime = queryParams.createTime[0]
        this.pager.endCreateTime = queryParams.createTime[1]
      } else {
        this.pager.beginCreateTime = ''
        this.pager.endCreateTime = ''
      }
      if (queryParams.payTime) {
        this.pager.beginPayTime = queryParams.payTime[0]
        this.pager.endPayTime = queryParams.payTime[1]
      } else {
        this.pager.beginPayTime = ''
        this.pager.endPayTime = ''
      }
      this.getOrderList()
    },
    handleMx() {
      this.$router.push('details')
    },
    handleTj() {
      this.$router.push('statistics')
    },
    async handleDc() {
      let ids = []
      this.selectOrders.map(item => {
        ids.push(item.id)
      })
      const [res, err] = await to(
        merchantTeacherFindMyOrderDetailExport({ ids: ids, ...this.pager }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '我的订单.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleXq(row) {
      this.$router.push({
        path: 'myOrderDetail',
        query: { id: row.id },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getOrderList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getOrderList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectOrders = val
    },
  },
}
</script>
